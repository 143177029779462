<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-09 16:28:41
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:02:56
 * @Description: 货品词条管理
 * @FilePath: \src\views\App\Iemma\CommodityItemsManage.vue
-->

<template>
  <div class="vue-box">
    <!-- ------------- 检索 ------------- -->
    <div class="table-search-box">
      <div class="table-search-title">检索</div>
      <div class="table-search-content">
        <div class="table-search-item">
          <label>分类筛选：</label>
          <el-select size="small" v-model="p.commodityClassId">
            <el-option key="All" label="全部" value="All"></el-option>
            <el-option
              v-for="item in itemsClassList"
              :key="item.kcoc_id"
              :label="item.name"
              :value="item.kcoc_id"
            ></el-option>
          </el-select>
        </div>

        <div class="table-search-item">
          <label>名称搜索：</label>
          <el-input
            size="small"
            v-model="p.search_key"
            @keyup.enter.native="_Load()"
            suffix-icon="xxxx"
          ></el-input>
        </div>

        <div class="table-search-btn">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="
              p.pageNo = 1;
              _Load();
            "
            >查询</el-button
          >
        </div>
      </div>
    </div>

    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="_OpenCreateCommodityItemModel()"
        >创建货品词条</el-button
      >
      <el-button
        size="small"
        type="info"
        icon="el-icon-refresh"
        @click="_Load()"
        >刷新</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      size="mini"
      stripe
      border
      row-key="id"
    >
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 货品名称 -->
      <el-table-column
        label="货品名称"
        prop="kcoi_name"
        align="center"
        show-overflow-tooltip
        width="130px"
      >
      </el-table-column>

      <!-- 货品英文名 -->
      <el-table-column
        label="货品英文名"
        prop="en_name"
        align="center"
        show-overflow-tooltip
        width="130px"
      >
      </el-table-column>

      <!-- 货品分类名 -->
      <el-table-column
        label="货品分类名"
        prop="kcoc_name"
        align="center"
        show-overflow-tooltip
        width="130px"
      >
      </el-table-column>

      <!-- 货品申报名 -->
      <el-table-column
        label="货品申报名"
        prop="kdei_name"
        align="center"
        show-overflow-tooltip
        width="130px"
      >
      </el-table-column>

      <!-- 附加检索词 -->
      <el-table-column
        label="附加检索词"
        prop="search_key"
        align="center"
        width="190px"
      ></el-table-column>

      <!-- 货品敏感等级 -->
      <el-table-column label="货品敏感等级" align="center" width="150px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="info"
            v-if="s.row.sensitive_level == 190010"
            >Ⅰ级【普货】</el-tag
          >
          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.sensitive_level == 190011"
            >Ⅱ级【内衣裤等】</el-tag
          >
          <el-tag
            size="small"
            effect="plain"
            type="warning"
            v-else-if="s.row.sensitive_level == 190012"
            >Ⅲ级【成人用品等】</el-tag
          >
          <el-tag
            size="small"
            effect="plain"
            type="danger"
            v-else-if="s.row.sensitive_level == 190013"
            >Ⅳ级【证件/文件】</el-tag
          >
          <el-tag size="small" effect="plain" type="success" v-else
            >未知</el-tag
          >
        </template>
      </el-table-column>

      <!-- 当前状态 -->
      <el-table-column label="当前状态" align="center" width="80px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-if="s.row.status == 1"
            >启用</el-tag
          >
          <el-tag size="small" effect="plain" type="danger" v-else>禁用</el-tag>
        </template>
      </el-table-column>

      <!-- 备注 -->
      <el-table-column label="备注">
        <template slot-scope="s"
          ><span>{{ s.row.notes }}</span></template
        >
      </el-table-column>

      <!-- 添加时间 -->
      <el-table-column
        label="创建时间"
        prop="add_time"
        align="center"
        width="140px"
      ></el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="center" width="200px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateCommodityItemModel(s.row.kcoi_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="_DelCommodityItem(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[25, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-commodity-item ref="nu-commodity-item"></nu-commodity-item>
  </div>
</template>

<script>
import NuCommodityItem from "./NUCommodityItem";
import {
  getCommodityItemsTable,
  delCommodityItem,
  getCommodityClassList,
} from "./api"; //页面专有接口
export default {
  name: "CommodityItemsManage",
  components: {
    NuCommodityItem,
  },
  data() {
    return {
      p: {
        pageNo: 1, //当前页
        pageSize: 25, //分页行数
        dataCount: 0, //数据总行数
        commodityClassId: "All", //分类筛选
        search_key: "", //名称搜索
      },
      TableData: [], // 数据集合
      itemsClassList: [], //分类列表
    };
  },
  watch: {
    "p.commodityClassId": {
      handler(val) {
        this.TableData = [];
        if (val !== "") {
          this._Load();
        }
      },
    },
  },
  methods: {
    //加载表格数据
    _Load() {
      getCommodityItemsTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
        classId: this.p.commodityClassId,
        searchKey: this.p.search_key,
      })
        .then((res) => {
          this.TableData = res.data.data;
          this.p.dataCount = res.data.dataCount;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 190002) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开货品词条添加组件
    _OpenCreateCommodityItemModel() {
      this.$refs["nu-commodity-item"].open(0);
    },

    //打开货品词条修改组件
    _OpenUpdateCommodityItemModel(id) {
      this.$refs["nu-commodity-item"].open(id);
    },

    //加载分类数据
    _LoadItemsClass() {
      getCommodityClassList()
        .then((res) => {
          this.itemsClassList = res.data;
        })
        .catch((err) => {
          this.itemsClassList = [];
          if (err.code === 180003) {
            this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //删除
    _DelCommodityItem(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除货品词条【" +
          data.name +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delCommodityItem({ kcoi_id: data.kcoi_id })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },
  },
  mounted() {
    this._Load();
    this._LoadItemsClass();
  },
};
</script>
